/* Table */
table {
  font-family: var(--font-family-sans-serif);
  margin: var(--gap) 0;
  border-gap: 0px;
  border-collapse: collapse;
  border-left: 1px solid var(--color-border);
  border-right: 1px solid var(--color-border);
  max-width: 100%;
  overflow: hidden;

  th, td {
    padding: var(--gap);
    line-height: var(--line-height-base);
  }

  tr {
    white-space: pre;
  }

  thead tr {
    border-top: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border);
  }

  tbody tr {
    border-bottom: 1px solid var(--color-border);
  }
}

.responsive-table {
  display: block;
  width: 100%;
  overflow-x: auto;
}