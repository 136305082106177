/* Icons */
.icon {
  display: flex;
  width: 25px;
  height: 25px;
  stroke-width: 2px;
  fill: none;

  &__svg {
    fill: inherit;
    background: inherit;
    stroke: currentcolor;
    stroke-width: inherit;
    stroke-linecap: round;
    stroke-linejoin: round;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transform: translateX(0);
    transition: fill var(--animation-duration) var(--animation-name), stroke var(--animation-duration) var(--animation-name);
  }

  &--xxs {
    width: 10px;
    height: 10px;
  }

  &--xs {
    width: 15px;
    height: 15px;
  }

  &--sm {
    width: 20px;
    height: 20px;
  }

  &--lg {
    width: 30px;
    height: 30px;
  }

  &--xl {
    width: 40px;
    height: 40px;
  }

  &--2x {
    width: 50px;
    height: 50px;
  }

  &--3x {
    width: 75px;
    height: 75px;
  }

  &--4x {
    width: 100px;
    height: 100px;
  }
}

.icon__wave {
  width: 80px;
  margin: 0 auto;
  margin-top: calc(-0.25 * var(--gap));
  margin-bottom: var(--gap);


  .line{
    fill:none;
    stroke: url(#gradient) var(--color-primary); 
    stroke-width:15;
    stroke-miterlimit:10;
  }
}

.wave-gradient {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
}
