/* Sidebar */
.sidebar {
  height: 100%;

  &__section {
    margin-bottom: var(--gap-lg);
    padding: var(--gap);
    background-color: var(--color-bg-accent-1);
    border-radius: var(--global-radius);
    border: 1px solid var(--color-border);
  }

  &__title {
    margin-bottom: 0;
    text-align: center;
    color: var(--color-text-accent-2);
  }

  &__description {
    margin-bottom: var(--gap);
    text-align: center;
    color: var(--color-text-accent-3);
  }

  &__tag {
    display: flex;
    padding: var(--gap-sm) 0;
    border-bottom: 1px solid var(--color-border);
    color: var(--color-text-accent-3);

    &:last-of-type { 
      border-bottom: none;
      padding-bottom: 0;
    }

    @mixin attention {
      color: var(--color-primary);
    }

    &-name {
      margin-bottom: 0;
      font-weight: var(--font-weight-semi-bold);
      color: inherit;
      flex: 1;
    }

    &-count { 
      font-weight: var(--font-weight-semi-bold); 
      color: inherit;
    }
  }

  &-ad {
    display: flex;
    align-items: center;
    justify-content: center;

    img { 
      margin: 0; 
      width: 300px;
      height: 250px;
      object-fit: cover;
      object-position: center;
    }
  }

  .social-links {
    justify-content: center;
    margin-bottom: var(--gap-sm);

    p { display: none; }
  }

  &__story {
    display: flex;
    margin-bottom: var(--gap);

    &:last-of-type {
      margin-bottom: 0;
    }
  
    &-title {
      font-weight: var(--font-weight-semi-bold);
      font-size: calc(0.9 * var(--font-size-base));
      color: var(--color-text-accent-2);
      flex: 1;
      padding-right: var(--gap);
      margin: 0;

      @mixin attention {
        color: var(--color-primary);
      }
    }

    &-image {
      height: calc(4 * var(--gap-rem));
      width: calc(4 * var(--gap-rem));
      
      img {
        margin: 0;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 100%;
        border-radius: var(--global-radius);
      }
    }
  }
}