/* Tag Card */
.top-tags {
  display: flex;

  .tag-card {
    @media (--viewport-xl) {
      margin-bottom: 0;
    }
  }
}

.tag-card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 12rem;
  max-height: 12rem;
  border-radius: var(--global-radius);    
  background-color: var(--color-bg-accent-1);
  border: 1px solid var(--color-border);
  overflow: hidden;
  margin-bottom: var(--gap);

  @media (--viewport-sm) {
    margin-bottom: var(--gap-lg);
  }

  @mixin attention {
    &:after {
      opacity: 0.6;
      background-image: linear-gradient(to bottom right, var(--color-black), var(--color-dark));
      transition: all var(--animation-duration) var(--animation-name);
    }
    .tag-card__name,
    .tag-card__count {
      color: var(--color-secondary);
    }
  }

  &:after {
    content: "";
    position: absolute;
    opacity: 0.4;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--color-primary);
    background-image: linear-gradient(to bottom right, var(--color-dark), var(--color-dark));
    transition: all var(--animation-duration) var(--animation-name);
  }

  &__image {
    position: relative;
    margin: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    object-fit: cover;
    z-index: 0;
  }

  &__name,
  &__count {
    position: absolute;
    z-index: 1;
  }

  &__name {
    font-size: calc(1.2 * var(--font-size-base));
    font-weight: var(--font-weight-semi-bold);
    color: color(var(--color-light) shade(10%));
    top: 6rem;
  }

  &__count {
    top: 3.5rem;
    font-size: calc(2 * var(--font-size-base));
    color: var(--color-light);
    font-weight: var(--font-weight-semi-bold);
  }
}

.tag-header {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--gap-lg);

  @media (--viewport-md) {
    flex-direction: row-reverse;
    padding: var(--gap-lg) 0;

    .geometric-bg {
      display: block;
      position: absolute;
      z-index: 1;
      left: 5%;
      top: 8%;
      z-index: 1;
      width: 50%;
      height: 80%;
      background-color: var(--color-bg-accent-2);
      border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
      box-shadow: -4vmin 4vmin 0 var(--color-bg-accent-1);
      transition: border-radius var(--animation-duration-lg) var(--animation-name), width var(--animation-duration-lg) var(--animation-name);
    }
  }

  @mixin attention {
    .geometric-bg {
      width: 50%;
      border-radius: 55% 45% 45% 55%/55% 55% 45% 45%;
      transition: 
        width var(--animation-duration-lg) var(--animation-name),
        border-radius var(--animation-duration-lg) var(--animation-name);
    }
  }

  &.no-img {
    background-color: var(--color-bg-accent-1);
    border-radius: var(--global-radius);
    padding: var(--gap);

    .geometric-bg {
      display: none;
    }

    .tag-header__description {
      display: none;
    }

    .tag-header__content {
      flex-basis: 100%;
    }
  }

  &__image {
    z-index: 2;
    flex: 1 0 auto;
    max-height: 30rem;
    overflow: hidden;
    border-radius: var(--global-radius);

    @media (--viewport-md) {
      flex-basis: 50%;
    }

    img {
      margin: 0;
      min-width: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: var(--global-radius);
      min-height: 100%;
      max-height: 100%;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    z-index: 3;

    @media (--viewport-md) {
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex-basis: 45%;
      padding-top: 0;
      padding-right: var(--gap);
    }
  }

  &__title {
    flex: 1 0 auto;
    margin: 0;

    @media (--viewport-md) {
      flex: 0;
      margin-bottom: var(--gap-sm);
    }
  }

  &__description {
    display: none;

    @media (--viewport-md) {
      display: block;
      max-width: 75%;
      margin-bottom: var(--gap);
      text-align: center;
      color: var(--color-text-accent-2);
    }
  }

  &__counter {
    font-weight: var(--font-weight-semi-bold);
    color: var(--color-primary);
  }
}