/* Social Links */
.social-links {
  display: flex;

  &__item {
    margin-right: var(--gap-sm);
    color: var(--color-text-accent-3);

    @mixin attention {
      color: var(--color-primary);
    }
  }
}

.social-share {
  display: flex;
  margin-bottom: var(--gap-lg);

  a {
    flex: 1;
    padding: var(--gap-sm);
    background-color: var(--color-bg-accent-1);
    color: var(--color-text-accent-2);
    border-radius: var(--global-radius);
    border: 1px solid var(--color-border);
    margin-right: var(--gap-sm);
    cursor: pointer;

    @mixin attention {
      background-color: var(--color-primary);
      color: var(--color-light);
      outline: none;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .icon {
    margin: 0 auto;
  }

  .copy-link {
    position: relative;

    &.has-tooltip {
      &:before,
      &:after {
        visibility: visible;
        opacity: 1;
        transition: 
          visibility var(--animation-duration) var(--animation-name),
          opacity var(--animation-duration) var(--animation-name);
      }
    }

    &:before {
      content: "";
      position: absolute;  
      visibility: hidden;
      opacity: 0;
      top: auto;
      right: 50%;
      bottom: -12px;
      margin-right: -5px;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid var(--color-bg-reverse);
      transition: 
          visibility var(--animation-duration) var(--animation-name),
          opacity var(--animation-duration) var(--animation-name);
    }

    &:after { 
      content: attr(data-label); 
      position: absolute;    
      visibility: hidden;
      opacity: 0;
      padding: var(--gap-sm) var(--gap);
      border-radius: var(--global-radius);
      background-color: var(--color-bg-reverse);
      color: var(--color-text-reverse);
      font-size: calc(0.8 * var(--font-size-base));
      font-weight: var(--font-weight-semi-bold);
      top: calc(3 * var(--gap-rem));
      right: 0;
      transition: 
        visibility var(--animation-duration) var(--animation-name),
        opacity var(--animation-duration) var(--animation-name);
      
      @media (--viewport-md) {
        right: 25px;
      }
    }
  }
}