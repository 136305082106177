/* Koenig editor styles */
.kg-embed-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 var(--gap-lg);
  min-width: 100%;
  max-width: 100%;

  figcaption {
    margin: var(--gap-sm) 0 0;
    color: var(--color-text-accent-3);
  }

  .fluid-width-video-wrapper {
    margin: 0;
  }
}

.kg-image-card,
.kg-gallery-card {
  margin: 0 0 var(--gap-lg);

  figcaption {
    padding: var(--gap-sm);
    color: var(--color-text-accent-3);
    text-align: center;
    font-size: calc(0.9 * var(--font-size-base));
    font-weight: var(--font-weight-semi-bold);
    font-style: italic;
  }
}

.kg-image-card img, 
.kg-gallery-card .kg-gallery-container {
  max-width: 100vw;
  margin-bottom: 0;
  display: block;
}

@media (--viewport-md) {
  .kg-image-card img {
    max-width: 100%;
  }

  .kg-image-wide img,
  .kg-width-wide img {
    max-width: 80vw;
  }

  .kg-image-full img,
  .kg-width-full img {
    max-width: 100vw;
  }
}

@media (--viewport-lg) {
  .kg-image-wide img,
  .kg-width-wide img {
    max-width: 70vw;
  }
}

.kg-gallery-container {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--gap);

  @media (--viewport-md) {
    width: 80vw;
    max-width: 80vw;
  }

  @media (--viewport-lg) {
    width: 70vw;
    max-width: 70vw;
  }
}

.post.has-sidebar {
  .kg-gallery-container {
    width: 100%;
    max-width: none;
  }

  .kg-image-card {
    img { max-width: 100%; }
  }
    
  .kg-image-card,
  .kg-gallery-card {
    width: calc(100% + 10vw);
  }
}

.kg-gallery-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.kg-gallery-image img {
  display: block;
  margin: 0;
  width: 100%;
  height: 100%;
}

.kg-gallery-row:not(:first-of-type) {
  margin: var(--gap-sm) 0 0 0;
}

.kg-gallery-image:not(:first-of-type) {
  margin: 0 0 0 var(--gap-sm);
}


/* Bookmark Style */
.kg-bookmark-card {
  background: var(--color-bg-accent-1);
  width: 100%;
  margin: 0;
  margin-bottom: var(--gap-lg);
  font-family: var(--font-family-sans-serif);

  @mixin attention {
    .kg-bookmark-title {
      color: var(--color-primary);
    }

    text-decoration: none;
  }
}

.kg-card + .kg-bookmark-card {
  margin-top: 0;
}

.kg-bookmark-container {
  display: flex;
  text-decoration: none;
  min-height: 148px;
  box-shadow: 0 0 0 1px var(--color-border);
  border-radius: var(--global-radius);
}

.kg-bookmark-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
}

.kg-bookmark-title {
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--gap);
}

.kg-bookmark-description {
  overflow-y: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;    
  font-size: calc(0.9 * var(--font-size-base));
  margin-bottom: var(--gap);
}

.kg-bookmark-thumbnail {
  position: relative;
  min-width: 33%;
  max-height: 100%;
  overflow: hidden;
}

.kg-bookmark-thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0 var(--global-radius) var(--global-radius) 0;
  margin: 0;
}

.kg-bookmark-metadata {
  display: flex;
  align-items: center;
  flex-wrap: wrap;    
  font-size: calc(0.9 * var(--font-size-base));
  font-weight: var(--font-weight-semi-bold);
}

.kg-bookmark-icon {
  width: calc(1.5 * var(--gap));
  height: calc(1.5 * var(--gap));
  margin: 0;
  margin-right: var(--gap-sm);
}

.kg-bookmark-author:after {
  content: "•";
  margin: 0 var(--gap-sm);
}

.kg-bookmark-publisher {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 240px;
}
