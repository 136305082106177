/* Sections */
.main {
  position: relative;
  flex: 1;
}

.header + .main {
  /* padding-top: calc(4 * var(--gap)); 

  @media (--viewport-md) {
    padding-top: calc(8 * var(--gap));
  } */
}

.section {
  margin-bottom: var(--gap-lg);

  &__title {
    margin-bottom: 0;
    color: var(--color-text-accent-2);

    &+.icon__wave { margin-left: 0; }
  }
}

.post-template,
.home-template {
  @media (--viewport-xl) {
    .col-xl-6 {
      max-width: calc(75% - 22rem);
    }

    .col-xl-9 {
      max-width: calc(100% - 22rem);
    }

    .col-xl-3.has-sidebar {
      flex: 0 0 calc(22*var(--gap-rem));
      max-width: calc(22*var(--gap-rem));
    }
  }
}