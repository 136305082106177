/* States */
.is-hidden {
  display: none;
  visibility: hidden;
  opacity: 0;
}

.is-flex {
  display: flex;
  flex-wrap: wrap;
}

.is-in-view {
  display: block;
}

/* Lazyload*/
.lazyload {
  opacity: 0.75;
  filter: blur(5px);
}

.lazyload.loaded {
  opacity: 1;
  filter: blur(0);
  transition: 
    all var(--animation-duration) var(--animation-name);
    /* opacity var(--animation-duration) var(--animation-name), 
    filter var(--animation-duration) var(--animation-name),
    transform var(--animation-duration) var(--animation-name),
    box-shadow var(--animation-duration) var(--animation-name); */
}

.initial.loaded {
  opacity: 1;
  filter: blur(0);
  transition: 
    all var(--animation-duration) var(--animation-name);
    /* opacity var(--animation-duration) var(--animation-name), 
    filter var(--animation-duration) var(--animation-name),
    transform var(--animation-duration) var(--animation-name),
    box-shadow var(--animation-duration) var(--animation-name); */
}

/* .loaded.is-in-view {
  opacity: 1;
  transition: opacity var(--animation-duration-lg) var(--animation-name), transform var(--global-tranition);
} */

.is-sticky {
  position: sticky;
  top: var(--gap-xl);
}
