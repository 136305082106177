/* Headings */
h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-family-sans-serif);
  color: inherit;
	word-break: break-word;

  a {	color: inherit;	}
}

h1 {
  font-size: calc(var(--font-size-base) * 2.5);
	font-size: calc(var(--font-size-base) * 1.6 + 0.8vw);
	font-weight: var(--font-weight-bold);
	line-height: calc(var(--line-height-base) * 0.8);
  margin: 0 0 calc(var(--gap) * 0.5);
}

h2 {
  font-size: calc(var(--font-size-base) * 2);
	font-size: calc(var(--font-size-base) * 1.3 + 0.8vw);
	font-weight: var(--font-weight-bold);
	line-height: calc(var(--line-height-base) * 0.85);
	margin: 0 0 calc(var(--gap) * 0.6);
}

h3 {
  font-size: calc(var(--font-size-base) * 1.8);
	font-size: calc(var(--font-size-base) * 1 + 0.8vw);
	font-weight: var(--font-weight-bold);
	line-height: calc(var(--line-height-base) * 0.9);
	margin: 0 0 calc(var(--gap) * 0.7);
}

h4 {
  font-size: calc(var(--font-size-base) * 1.5);
	font-size: calc(var(--font-size-base) * 0.9 + 0.6vw);
	font-weight: var(--font-weight-bold);
	line-height: calc(var(--line-height-base) * 0.95);
	margin: 0 0 calc(var(--gap) * 0.8);
}

h5 {
	font-size: calc(var(--font-size-base) * 1.25);
	font-size: calc(var(--font-size-base) * 0.9 + 0.4vw);
	font-weight: var(--font-weight-bold);
	line-height: calc(var(--line-height-base) * 1);
	margin: 0 0 calc(var(--gap) * 0.9);
}

h6 {
  font-size: calc(var(--font-size-base) * 1);
	font-size: calc(var(--font-size-base) * 0.9 + 0.1vw);
	font-weight: var(--font-weight-bold);
	line-height: calc(var(--line-height-base) * 1);
	margin: 0 0 calc(var(--gap) * 1);
}