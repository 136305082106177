/* Footer */
.footer {
  color: var(--color-text-accent-2);

  &__top {
    padding-top: var(--gap);
    border-top: 1px solid var(--color-border);
  }

  h5 { 
    font-size: calc(1.2 * var(--font-size-base)); 
  }

  .nav {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: var(--gap);
    color: var(--color-text-accent-3);

    @media (--viewport-md) {
      flex-direction: column;
    }

    /* li { margin-right: var(--gap); } */

    a { 
      display: block; 
      padding-bottom: var(--gap-sm);
      padding-right: var(--gap);
    }
  }

  .social-links {
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-bottom: var(--gap);
    color: var(--color-text-accent-3);

    &__item {
      display: flex;
      margin-bottom: var(--gap-sm);
      color: inherit;

      .icon { margin-right: var(--gap-sm); }

      p { margin-bottom: 0; }
    }
  }

  #footer-subscribe {
    color: var(--color-text-accent-3);
  }

  .subscribe-form {
    display: flex;
    position: relative;
    height: 2.8rem;
    max-width: calc(25 * var(--gap-rem));

    input { 
      margin-bottom: 0; 
      padding: var(--gap-sm);
      padding-left: var(--gap);
      border-radius: calc(2 * var(--font-size-base));
      background-color: transparent;
      transition: none;

      &:focus,
      &:active {
        transition: none;

        &+button .icon {
          transform: rotate(360deg);
          transition: transform var(--animation-duration) var(--animation-name);
        }
      }
    }

    button {
      position: absolute;
      right: 0;
      padding: var(--gap-sm);
      margin: 2px;
      border-radius: var(--global-radius-rounded);
      background-color: var(--color-primary);
      color: var(--color-light);

      &:hover {
        .icon {
          transform: rotate(360deg);
          transition: transform var(--animation-duration) var(--animation-name);
        }
      }
    }
  } 

  &__bottom {
    display: flex;
    justify-content: center;
    padding: var(--gap);
    color: var(--color-text-accent-3);
  }

  a:hover,
  a:focus,
  a:active {
    color: var(--color-primary);
  }
}