/* Toc */
.toc-wrapper {
  z-index: 30;
  margin-bottom: var(--gap-lg);
  background-color: var(--color-bg-accent-1);

  @media (--viewport-sm) {
    background-color: initial;
  }

  &.is-sticky {
    top: 3em;
  }
}

.toc {
  overflow-y:auto;

  & > .toc-list {
    overflow:hidden;
    position:relative;
  }

  &-list {
    margin: 0;
    padding-left: calc(0.75 * var(--gap));

    li { list-style:none; }
  }
}

a.toc-link {
  color: var(--color-text-accent-2);
  text-decoration: underline;
  height: 100%;
  padding: var(--gap-xs);
  display: block;

  @mixin attention {
    color: var(--color-primary);
  }
}

.is-collapsible {
  max-height: 1000px;
  overflow:hidden;
  transition:all 300ms ease-in-out;
}

.is-collapsed {
  max-height: none;

  .toc-link::before {
    background-color: transparent;
  }

  @media (--viewport-xl) {
    max-height: 0;
  }
}

.is-position-fixed { 
  position: fixed !important;
  top: 0;
} 

.is-active-link{
  font-weight: var(--font-weight-semi-bold);
}

.toc-link::before{
  background-color: var(--color-bg-accent-2);
  content:' ';
  display: inline-block;
  height: 25px;
  left: 0;
  margin-top: -1px;
  position: absolute;
  width: 2px;
}

.is-active-link::before{
  background-color: var(--color-primary);
}

.post-toc {
  position: relative;
  padding: var(--gap);
  background-color: var(--color-bg-accent-1);

  @media (--viewport-lg) {
    position: sticky;
    top: calc(4 * var(--gap));
  }

  &__head {
    display: flex;
    cursor: pointer;

    @mixin attention {
      .post-toc__icon {
        color: var(--color-primary);
      }
    }

    @media (--viewport-xl) {
      cursor: initial;
    }
  }

  h4 { 
    font-size: calc(1.2 * var(--font-size-base));
    margin-bottom: 0; 
    flex: 1;
  }

  &__icon {
    width: 25px;
    height: 25px;

    &.is-rotated {
      transform: rotate(90deg);
    }

    @media (--viewport-xl) {
      display: none;
    }
  }

  &__content { 
    display: none;

    &.is-active {
      display: block;
    }

    @media (--viewport-xl) {
      display: block;
    }
  }

  .js-toc > .toc-list {
    padding-left: 0;
  }
}