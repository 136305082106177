/* Misc */
hr {
  border: 0;
  height: 1px;
  background-color: var(--color-bg-accent-2);
  margin: var(--gap) 0;
}

abbr {
  -webkit-font-variant: small-caps;
  -moz-font-variant: small-caps;
  -ms-font-variant: small-caps;
  font-variant: small-caps;
  font-weight: var(--font-weight-bold);
  text-transform: lowercase;
  color: gray;
}

abbr[title]:hover {
  cursor: help;
}

::selection {
  color: var(--color-text);
  background: color(var(--color-primary) blend(var(--color-bg) 75%)); /* WebKit/Blink Browsers */
}
::-moz-selection {
  color: var(--color-text);
  background: color(var(--color-primary) blend(var(--color-bg) 75%)); /* Gecko Browsers */
}

input,
abbr,
acronym,
blockquote,
code,
kbd,
q,
samp,
var {
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}